import {
  createAsyncThunk,
  createSelector,
  createSlice,
  nanoid,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { paginator, ROUTES } from "@/constants";
import { globalNavigate } from "@/GlobalNavigate";
import { PortfolioService } from "@/services";
import {
  IDuplicate,
  IMarkAsDefaultTemplatePlayload,
  IPortfolio,
  IPortfolioPayload,
} from "@/types/portfolio";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IPortfolioDataState {
  portfolioList: IPortfolio[];
  selected: IPortfolio | null;
  isLoading: boolean;
  isLoadingSelected: boolean;
  isLoadingDuplicate: boolean;
  isEdit: boolean;
  isNew: boolean;
  page: number;
  perPage: number;
  notFound: boolean;
  copiedTemplateId: number | null;
}

const initialState: IPortfolioDataState = {
  portfolioList: [],
  selected: null,
  isLoading: false,
  isLoadingSelected: false,
  isEdit: false,
  isNew: false,
  isLoadingDuplicate: false,
  page: paginator.page,
  perPage: paginator.default,
  notFound: false,
  copiedTemplateId: null,
};

export const getPortfolioList = createAsyncThunk("portfolio/getList", async () => {
  const results = await PortfolioService.getPortfolioList();
  return results.data.result;
});

export const getPortfolio = createAsyncThunk(
  "portfolio/get",
  async (id: number, { rejectWithValue }) => {
    try {
      const results = await PortfolioService.getPortfolio(id);
      return results.data.result;
    } catch (e) {
      const err = e as AxiosError<{ error?: { error_code?: number; status_code?: number } }>;

      if (
        err.response?.data.error?.error_code === 250 &&
        err.response?.data.error?.status_code === 404
      ) {
        return rejectWithValue("not-found");
      }
      return rejectWithValue(null);
    }
  }
);

export const savePortfolio = createAsyncThunk(
  "portfolio/save",
  async (data: IPortfolioPayload, thunkAPI) => {
    try {
      const results = await PortfolioService.savePortfolio(data);
      thunkAPI.dispatch(getPortfolioList());
      if (results.data.id) {
        globalNavigate(`${ROUTES.portfolioBuilder.path}/edit/${results.data.id}`);
      }
      return results.data;
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(err.response.data?.error?.message);
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "portfolio/update",
  async ({ id, data }: { id: number; data: IPortfolioPayload }, ThunkAPI) => {
    const results = await PortfolioService.updatePortfolio(id, data);
    ThunkAPI.dispatch(getPortfolioList());
    return results.data.result;
  }
);

export const deletePortfolio = createAsyncThunk("portfolio/delete", async (id: number) => {
  await PortfolioService.deletePortfolio(id);
  return id;
});

export const duplicatePortfolio = createAsyncThunk(
  "portfolio/duplicate",
  async ({ id, data }: { id: number; data: IDuplicate }) => {
    data.name = `${data.name}-copy-${nanoid(12)}`;
    const result = await PortfolioService.duplicatePortfolio(id, data);
    return result.data.result;
  }
);

export const fetchCopyPortfolioTemplate = createAsyncThunk(
  "portfolio/duplicateTemplate",
  async (id: number) => {
    const response = await PortfolioService.copyTemplatePortfolio(id);
    return {
      data: response.data,
    };
  }
);

export const markAsDefaultTemplate = createAsyncThunk(
  "portfolio/markAsDefault",
  async ({ id, data }: { id: number; data: IMarkAsDefaultTemplatePlayload }) => {
    const result = await PortfolioService.markAsDefaultTemplate(id, data);
    return result.data;
  }
);

export const fetchChangeFavoriteStatus = createAsyncThunk(
  "portfolio/markAsDefault",
  async (config: { value: boolean; id: number }) => {
    const { data } = await PortfolioService.changeFavoriteStatus(config);
    return data.result;
  }
);

export const portfolioSlice = createSlice({
  name: "portfolioManage",
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<IPortfolio>) => {
      state.selected = action.payload;
    },
    resetSelected: (state) => {
      state.selected = null;
    },
    setEdit: (state, actions: PayloadAction<boolean>) => {
      state.isEdit = actions.payload;
    },
    setNew: (state, actions: PayloadAction<boolean>) => {
      state.isNew = actions.payload;
    },
    changeFavoriteStatus: (state, { payload }: PayloadAction<number | undefined>) => {
      if (state.portfolioList && payload) {
        const portfolio = state?.portfolioList.find((i) => i.id === payload);
        if (portfolio) portfolio.show_on_home = !portfolio.show_on_home;
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setPerPage: (state, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    resetCopiedTemplateId: (state) => {
      state.copiedTemplateId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolioList.fulfilled, (state, action) => {
        state.portfolioList = action.payload;
        state.isLoading = false;
      })
      .addCase(getPortfolioList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPortfolioList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(savePortfolio.fulfilled, (state, action) => {
        state.portfolioList.push(action.payload);
        state.isLoading = false;
        notification.success("Portfolio Saved!");
      })
      .addCase(savePortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(savePortfolio.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          notification.error(action.payload as string);
        }
      });

    builder
      .addCase(updatePortfolio.fulfilled, (state, action) => {
        state.portfolioList = state.portfolioList.map((portfolio) => {
          if (portfolio.id === action.payload.id) {
            return action.payload;
          }
          return portfolio;
        });
        state.selected = action.payload;
        state.isLoading = false;
        notification.success("Portfolio has successful updated.");
      })
      .addCase(updatePortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePortfolio.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(deletePortfolio.fulfilled, (state, action) => {
        state.portfolioList = state.portfolioList.filter(({ id }) => id !== action.payload);
        state.isLoadingSelected = false;
        notification.success("Portfolio has successful deleted.");
      })
      .addCase(deletePortfolio.pending, (state) => {
        state.isLoadingSelected = true;
      })
      .addCase(deletePortfolio.rejected, (state, action) => {
        state.isLoadingSelected = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    builder
      .addCase(getPortfolio.fulfilled, (state, action) => {
        const { payload } = action;

        state.selected = initialState.selected;
        state.selected = payload;
        state.isLoadingSelected = false;
      })
      .addCase(getPortfolio.pending, (state) => {
        state.isLoadingSelected = true;
        state.notFound = false;
      })
      .addCase(getPortfolio.rejected, (state, { payload }) => {
        state.isLoadingSelected = false;
        if (payload === "not-found") {
          state.notFound = true;
        }
      });
    // Duplicate portfolio
    builder
      .addCase(duplicatePortfolio.fulfilled, (state, action) => {
        state.portfolioList.push({
          ...action.payload,
          permissions: {
            has_read: true,
            has_delete: true,
            has_write: true,
          },
        });
        notification.success("Portfolio has successful copied.");
        state.isLoadingDuplicate = false;
      })
      .addCase(duplicatePortfolio.pending, (state) => {
        state.isLoadingDuplicate = true;
      })
      .addCase(duplicatePortfolio.rejected, (state, action) => {
        state.isLoadingDuplicate = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });

    // Duplicate Portfolio Template
    builder
      .addCase(fetchCopyPortfolioTemplate.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const newPortfolio = {
          ...data,
          permissions: {
            has_read: true,
            has_delete: true,
            has_write: true,
          },
        };
        state.portfolioList = [newPortfolio, ...state.portfolioList];
        notification.success("Portfolio template has successful copied.");
        state.isLoadingDuplicate = false;
        state.copiedTemplateId = data.id || null;
        state.page = 1;
      })
      .addCase(fetchCopyPortfolioTemplate.pending, (state) => {
        state.isLoadingDuplicate = true;
      })
      .addCase(fetchCopyPortfolioTemplate.rejected, (state, action) => {
        state.isLoadingDuplicate = false;
        if (action.error.message) {
          notification.error(action.error.message);
        }
      });
  },
});

export const {
  setSelected,
  resetSelected,
  setEdit,
  setNew,
  changeFavoriteStatus,
  setPage,
  setPerPage,
  resetCopiedTemplateId,
} = portfolioSlice.actions;

const state = (state: RootState) => state;

export const managePortfolioState = createSelector(state, (state) => state.portfolio);

export default portfolioSlice.reducer;
