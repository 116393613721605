import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { portfolioBuilderService } from "@/services";
import {
  IPortfolioBuilderData,
  IPortfolioBuilder,
  IPortfolioBuilderPayload,
  IResponseErorr,
} from "@/types";
import { notification } from "@/utils/notification";

import { RootState } from "../index";

interface IPortfolioBuilderState {
  portfolioBase: IPortfolioBuilder[];
  portfolioOverlay: IPortfolioBuilder[];
  benchmark: string[];
  rebalance: string;
  results: IPortfolioBuilderData;
  isLoading: boolean;
  isPerformanceView: boolean;
}

const getInitResult = () => ({
  portfolio: {},
  metrics: {},
});

const initialState: IPortfolioBuilderState = {
  portfolioBase: [],
  portfolioOverlay: [],
  benchmark: [],
  rebalance: "",
  results: getInitResult(),
  isLoading: false,
  isPerformanceView: false,
};

export const wrapWithAxiosResponseError = (_err: unknown) => {
  const error = _err as AxiosError<IResponseErorr> & { isCanceled: boolean };
  if (Object.hasOwn(error, "isCanceled")) return { isCanceled: true };
  else return { error: error.response?.data.error || null, msg: error.message, code: error.code };
};

export const getPortfolioBuilterResult = createAsyncThunk(
  "portfolioBuilder/getData",
  async ({ id, start, end }: { id: string; start?: string; end?: string }, { rejectWithValue }) => {
    try {
      const results = await portfolioBuilderService.portfolioBuilder(id, start, end);
      if (typeof results?.data === "string") {
        return results && JSON.parse(results?.data as unknown as string);
      }
      return results.data.result;
    } catch (err) {
      return rejectWithValue(wrapWithAxiosResponseError(err));
    }
  }
);

export const getPortfolioBuilterLiveResult = createAsyncThunk(
  "portfolioBuilder/getData",
  async (data: IPortfolioBuilderPayload, { rejectWithValue }) => {
    try {
      const results = await portfolioBuilderService.portfolioBuilderLive(data);
      if (typeof results?.data === "string") {
        return results && JSON.parse(results?.data as unknown as string);
      }
      return results.data.result;
    } catch (err) {
      return rejectWithValue(wrapWithAxiosResponseError(err));
    }
  }
);

export const portfolioBuilderSlice = createSlice({
  name: "portfolioBuilder",
  initialState,
  reducers: {
    setPortfolioBase: (state, action: PayloadAction<IPortfolioBuilder[]>) => {
      state.portfolioBase = action.payload;
    },
    setPortfolioOverlay: (state, action: PayloadAction<IPortfolioBuilder[]>) => {
      state.portfolioOverlay = action.payload;
    },
    setBenchmark: (state, actions: PayloadAction<string[]>) => {
      state.benchmark = actions.payload;
    },
    setRebalance: (state, action: PayloadAction<string>) => {
      state.rebalance = action.payload;
    },
    resetPortfolio: () => initialState,
    resetResults: (state) => {
      state.results = getInitResult();
      state.isPerformanceView = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolioBuilterResult.fulfilled, (state, { payload }) => {
        if (payload === null) {
          state.results = getInitResult();
          state.isPerformanceView = true;
        } else if (payload?.message) {
          notification.warning(`${payload?.message}`);
        } else {
          state.results = payload;
          state.isPerformanceView = true;
        }
        state.isLoading = false;
      })
      .addCase(getPortfolioBuilterResult.pending, (state) => {
        state.isLoading = true;
        state.results = getInitResult();
        state.isPerformanceView = false;
      })
      .addCase(getPortfolioBuilterResult.rejected, (state, { payload }: Record<string, any>) => {
        state.isLoading = false;
        if (payload && !Object.hasOwn(payload, "isCanceled")) {
          if (payload?.error?.status_code === 404) {
            notification.warning(
              `${payload?.error?.message} \n Details: ${payload?.error?.details}`
            );
          }
          if (payload.code === "ERR_NETWORK") {
            notification.error("Network error, please check your network connection.");
          } else
            notification.warning(
              "The current portfolio configuration is incorrect. Please modify it and try again.",
              false,
              false
            );
        }
      });
  },
});

export const {
  setPortfolioBase,
  setPortfolioOverlay,
  setRebalance,
  resetPortfolio,
  resetResults,
  setBenchmark,
} = portfolioBuilderSlice.actions;

const state = (state: RootState) => state;

export const portfolioBuilderState = createSelector(state, (state) => state.portfolioBuilder);

export default portfolioBuilderSlice.reducer;
