import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { strategiesService } from "@/services";

interface IInitialState {
  hashList: string[] | null;
}

export const initialState: IInitialState = {
  hashList: null,
};

export const fetchMarkAsReadPerformance = createAsyncThunk(
  "performance/markAsRead",
  async (taskId: string) => {
    await strategiesService.markAsReadPerformance(taskId);
  }
);

export const performanceSlice = createSlice({
  name: "performance",
  initialState,
  reducers: {
    setPerformanceHashList: (state, { payload }: PayloadAction<string[]>) => {
      state.hashList = payload;
    },
    removeHashItem: (state, { payload }: PayloadAction<string>) => {
      if (state.hashList) state.hashList = state.hashList?.filter((hash) => hash !== payload);
    },
  },
});

export const { setPerformanceHashList } = performanceSlice.actions;
export default performanceSlice.reducer; // export reducer
