import { FC } from "react";

import {
  BacktestingEmptyResult,
  Button,
  Confirm,
  DatePickerRange,
  PerformanceResult,
} from "@/components";
import { DropdownSearch } from "@/components/Dropdown/DropdownSearch";
import { IPortfolioStrategy } from "@/types";

import { BaseStrategyItem } from "./BaseStrategyItem/BaseStrategyItem";
import { useCompareForm } from "./hooks/useCompareForm";
import { StrategiesGroupList } from "./StrategiesGroupList/StrategiesGroupList";

import styles from "./StrategyCompare.module.scss";

import { ReactComponent as CompareIcon } from "@images/compare.svg";
import { ReactComponent as AddIcon } from "@images/plus.svg";

interface IProps {
  list: {
    core: (IPortfolioStrategy & { id?: number })[];
    overlay: (IPortfolioStrategy & { id?: number })[];
    benchmark: { name: string; ticker: string }[];
    ticker: string[];
    portfolios: { name: string; id: number }[];
  };
}

const VISIBLE_ROWS = 10;

export const StrategyCompare: FC<IProps> = ({ list }) => {
  const {
    selected,
    selectedList,
    selectedBase,
    selectedBaseItem,
    strategiesList,
    isAddItemDisabled,
    isCompareDataDisabled,
    isBacktestingResultEmpty,

    resultRef,

    isCompareResultEmpty,
    isDataReady,
    progress,
    columns,
    tableData,
    chartData,
    date,

    compareHandler,
    cancelHandler,
    addStrategyHandler,
    removeHandler,
    selectItemHandler,
    selectDateHandler,
    selectBaseItemHandler,
  } = useCompareForm(list);

  // console.log("Str:", strategiesList);
  // "s4SPYwithTestBenchmark-2###type-portfolio###id-306###subtype-portfolio###info-Portfolio"
  // "s4TestSPYwithBenchmark###type-portfolio###id-305###subtype-portfolio###info-Portfolio"
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <section className={styles.mainSection}>
          {progress ? (
            <div className={styles.progressContainer}>
              <Confirm
                onCancel={cancelHandler}
                progress={progress}
                text="Comparison is in progress. This may take a few minutes. You can wait a little bit to view the result or stop to cancel the comparison."
                type="compare"
              />
            </div>
          ) : null}
          <form
            className={`${styles.form} ${progress ? styles.disabled : ""}`}
            onSubmit={(ev) => ev.preventDefault()}
          >
            <div className={styles.inputGroup}>
              <DropdownSearch
                label="Base Strategy, Portfolio, Ticker or Benchmark"
                placeholder="Select base strategy, portfolio, ticker or benchmark name"
                list={strategiesList}
                onSelect={selectBaseItemHandler}
                selected={selectedBase}
                name="strategy"
                visibleRows={VISIBLE_ROWS}
              />
              <DatePickerRange
                onSelect={selectDateHandler}
                label="Select Date Interval"
                date={date || undefined}
                disabled={false}
              />
              <div className={styles.btnGroup}>
                <Button
                  type="secondary"
                  text="Compare"
                  onClick={compareHandler}
                  IconComponent={CompareIcon}
                  disabled={isCompareDataDisabled}
                />
              </div>
            </div>

            <BaseStrategyItem name={selectedBaseItem.name} type={selectedBaseItem.type} />

            <div className={styles.inputGroup}>
              <DropdownSearch
                label="Strategy, Portfolio, Ticker or Benchmark to compare"
                placeholder="Select strategy, portfolio, ticker or benchmark name to compare with ..."
                list={strategiesList}
                onSelect={selectItemHandler}
                selected={selected}
                name="strategy"
                visibleRows={VISIBLE_ROWS}
                disable={!selectedBase}
              />
              <div className={styles.btnWrapper}>
                <Button
                  text="Add Strategy"
                  onClick={addStrategyHandler}
                  IconComponent={AddIcon}
                  disabled={isAddItemDisabled}
                />
              </div>
            </div>

            <StrategiesGroupList list={selectedList} onRemove={removeHandler} />

            <BacktestingEmptyResult
              isVisible={isBacktestingResultEmpty}
              title="No Compare Data was generated with this strategies configuration."
            />
          </form>
        </section>
        <section className={styles.resultSection} ref={resultRef}>
          <PerformanceResult
            isEmptyResult={isCompareResultEmpty}
            isPreviewReady={isDataReady}
            columns={columns}
            tableData={tableData}
            chartData={chartData}
          />
        </section>
      </div>
    </div>
  );
};
