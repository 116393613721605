import { FC, Fragment, memo } from "react";

import styles from "./Tab.module.scss";

import { ReactComponent as ConditionIcon } from "@images/condition.svg";

export interface ITabItem {
  key: string;
  title: string;
  component: JSX.Element;
}

interface IProps {
  tabs: ITabItem[];
  selected: string;
  onChange: (key: string) => void;
  disabled?: boolean;
  isReadonly?: boolean;
}

export const Tab: FC<IProps> = memo(({ tabs, selected, disabled, isReadonly, onChange }) => {
  const tabSelectHandler = (key: string) => {
    onChange(key);
  };
  return (
    <div className={styles.container} data-disabled={disabled} data-readonly={isReadonly}>
      <div className={styles.tabsNavigation}>
        {tabs.map((tab, idx) => (
          <div
            className={`${styles.tabNavItem} ${selected === tab.key ? styles.active : ""}`}
            key={tab.key}
            onClick={() => tabSelectHandler(tab.key)}
          >
            <span className={`${styles.iconContainer} ${idx === 0 ? styles.rotated : ""}`}>
              <ConditionIcon />
            </span>
            <span className={styles.label}>{tab.title}</span>
          </div>
        ))}
      </div>
      <div className={styles.tabActiveContainer}>
        {tabs.map((tab) =>
          tab.key === selected ? <Fragment key={tab.key}>{tab.component}</Fragment> : null
        )}
      </div>
    </div>
  );
});
