import { FC } from "react";

import { CreateNew, Heading, InputItem, LoaderWrapper, NoData, Pagination } from "@/components";
import { ROUTES } from "@/constants";
import { usePagination, useSearchTextInList } from "@/hooks";
import { IPortfolio } from "@/types";

import { useMyPortfolios } from "../hooks/useMyPortfilos";
import { PortfolioItem } from "../PortfolioItem/PortfolioItem";

import styles from "./MyPortfolios.module.scss";

interface IProps {
  name?: string;
}

export const MyPortfolios: FC<IProps> = () => {
  const { portfoliosPaginator, portfolioList, isLoading, copiedTemplateId } = useMyPortfolios();
  const { page, perPage, setPage, setPerPage, perPageList } = portfoliosPaginator;

  const { filteredList, searchText, handleSearch, handleEnter } = useSearchTextInList(
    portfolioList,
    setPage
  );

  const { totalPages, paginatedList } = usePagination<IPortfolio>({
    list: filteredList,
    page,
    perPage,
  });

  return (
    <LoaderWrapper loading={isLoading} fullWidth>
      <div className={styles.container}>
        <div className={styles.headingGroup}>
          <Heading type="h3" className={styles.subHeading}>
            My Portfolios
          </Heading>
        </div>
        <div className={styles.searchWrapper}>
          <InputItem
            onChange={handleSearch}
            onEnter={handleEnter}
            label=""
            name="strategy-search"
            type="search"
            placeholder="Search Portfolio"
            noPaddingBottom
          />
        </div>
        <div className={`${styles.wrapper}`}>
          {searchText && paginatedList.length === 0 ? (
            <NoData text="No portfolios were found based on your search." />
          ) : null}
          <div className={styles.paginationContainer}>
            <Pagination
              onPage={setPage}
              onChangePerPage={setPerPage}
              perPage={perPage}
              currentPage={page}
              totalCount={totalPages}
              count={portfolioList?.length || 0}
              perPageList={perPageList}
              countLabel={"Total Portfolios"}
            />
          </div>
          {paginatedList.length === 0 && searchText ? null : (
            <div className={styles.inner}>
              <div className={styles.listWrapper}>
                {searchText ? null : (
                  <CreateNew to={ROUTES.portfolioCreate.path} text="Create New Portfolio" />
                )}
                {paginatedList?.map((portfolio, idx) => (
                  <PortfolioItem
                    key={portfolio.id}
                    portfolio={portfolio}
                    searchText={searchText}
                    idx={idx}
                    link={`${ROUTES.portfolioEdit.path}/${portfolio.id}`}
                    isCopiedItem={copiedTemplateId === portfolio.id}
                    isMyPortfolio
                    hasMenu
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
};
