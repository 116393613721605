import axios, { CancelTokenSource } from "axios";
import { useEffect, useRef } from "react";

export function useAbortRequest(): CancelTokenSource {
  const abort = useRef(axios.CancelToken.source());
  useEffect(() => {
    const abortController = abort.current;
    return () => {
      console.warn("Abort request operation by user.");
      abortController.cancel();
    };
  }, []);

  return abort.current;
}
