import { FC } from "react";

import styles from "./Status.module.scss";

import { ReactComponent as ClearIcon } from "@images/clear.svg";
import { ReactComponent as InfoIcon } from "@images/info.svg";

interface IProps {
  placeholder?: string;
  status: string | null;
  error: string | null;
  label?: string;
  onClear?: () => void;
  disabled?: boolean;
}

export const Status: FC<IProps> = ({ status, error, placeholder, label, disabled, onClear }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner} data-disabled={disabled}>
        <span>{label} </span>
        <p className={`${styles.text} ${!status ? styles.placeholder : ""}`}>
          {status || placeholder}
        </p>
        {!!onClear && !!status && !disabled ? (
          <div className={styles.iconContainer} onClick={onClear}>
            <ClearIcon />
          </div>
        ) : null}
      </div>
      {error && (
        <div className={styles.error}>
          <div className={styles.icon}>
            <InfoIcon />
          </div>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
