import { FC, useMemo, useRef } from "react";

import {
  BacktestingEmptyResult,
  Button,
  GoBack,
  InfoHeading,
  ModalLayout,
  PerformanceResult,
  SubTitle,
} from "@/components";
import { Confirm } from "@/components/Dialogs/Confirm";
import { EmptyContainer } from "@/components/EmptyContainer/EmptyContainer";
import { modelingStatus, ROUTES } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { Constructor, CreateBaseForm, Tab } from "@/features/StrategyCreate";

import { useMarkAsReadPerformance } from "./hooks/useMarkAsReadPerformance";
import { useTemplatePreview } from "./hooks/useTemplatePreview";

import styles from "./StrategyCreate.module.scss";

import { ReactComponent as PerformanceIcon } from "@images/chart-histogram-two-filled.svg";

interface IProps {
  isEditMode?: boolean;
}

export const StrategiesTemplatePreview: FC<IProps> = ({ isEditMode }) => {
  const {
    isEmptyResultVisible,
    strategyDescription,
    newStartegyForm,
    isPreviewReady,
    strategyName,
    sectionRef,
    showModal,
    chartData,
    tableData,
    isEmpty,
    columns,

    cancelModelingHandle,
    confirmModelingHandle,
    getEmptyResultTitle,
    getEmptyResultInfo,
    modelingTestHandle,
    selectTabHandler,
    backHandler,

    textInfo,
    video,
  } = useTemplatePreview();

  const containerRef = useRef<HTMLTableSectionElement | null>(null);

  const TabEntry = () => {
    return <Constructor isSaving={true} isTemplate={true} />;
  };
  const TabExit = () => {
    return <Constructor isSaving={true} isTemplate={true} />;
  };

  const tabsList = useMemo(
    () => [
      { key: "entry", title: "Entry Conditions", component: <TabEntry /> },
      { key: "exit", title: "Exit Conditions", component: <TabExit /> },
    ],
    []
  );

  // remove viewed performance data form list
  useMarkAsReadPerformance();

  const goBackLink = `${ROUTES.modelingPortfolioBuilder.path}?current_tab=Single Strategy`;

  return (
    <EmptyContainer
      isEmpty={isEmpty}
      title="Sorry, Such Strategy Template not found..."
      goBackLink={goBackLink}
    >
      <div className={styles.container}>
        <div className={styles.inner}>
          <InfoHeading
            helpInfo={textInfo}
            faqList={faqDescriptionList.portfolioSingleStrategy}
            tutorialLink={video}
          >
            Portfolio Builder - <SubTitle text="Single Strategy" />
          </InfoHeading>
          <div className={styles.editSubHeading}>
            <p>Strategy Template:</p> <p>{strategyName}</p>
          </div>

          <div className={styles.headerNavContainer}>
            <div className={styles.navGroup}>
              <GoBack onClick={backHandler} />
            </div>
            <div className={styles.buttonsGroup}>
              <div className={styles.btnWrapper}>
                <Button
                  type="secondary"
                  text="Model"
                  onClick={modelingTestHandle}
                  IconComponent={PerformanceIcon}
                />
              </div>
            </div>
          </div>
          <section className={styles.mainSection} ref={containerRef}>
            <BacktestingEmptyResult
              isVisible={isEmptyResultVisible}
              title={getEmptyResultTitle()}
              info={getEmptyResultInfo()}
              heading="Model status"
            />
            <section className={`${styles.topSection}`}>
              <CreateBaseForm
                isTemplate={true}
                description={strategyDescription}
                isModified={false}
              />
            </section>
            <section className={`${styles.bottomSection}`}>
              <Tab
                tabs={tabsList}
                selected={newStartegyForm.activeCondition}
                onChange={selectTabHandler}
              />
            </section>
            <section
              className={`${isPreviewReady ? styles.previewSection : ""}`}
              ref={(el) => {
                sectionRef.current.result = el;
              }}
            >
              <PerformanceResult
                isEmptyResult={isEmptyResultVisible}
                isPreviewReady={isPreviewReady}
                chartData={chartData}
                tableData={tableData}
                columns={columns}
                fileTitleName={`Single-Strategy ${strategyName}`}
              />
            </section>
          </section>
        </div>
        <ModalLayout show={showModal !== null}>
          <Confirm
            onCancel={cancelModelingHandle}
            onConfirm={confirmModelingHandle}
            text={modelingStatus.starting.text}
            type={"modeling"}
            progress={showModal === "progress"}
          />
        </ModalLayout>
      </div>
    </EmptyContainer>
  );
};
