import { ChangeEvent, useEffect, useState } from "react";

import { debounce } from "@/utils";

export const useSearchTextInList = <U extends { name: string }>(
  list: U[],
  setPage?: (page: number) => void
) => {
  const [searchText, setSearchText] = useState<string>("");
  const [filteredList, setFilteredList] = useState(list);

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    if (setPage) setPage(1);
    if (value) {
      setFilteredList(
        list.filter((strategy) => strategy.name.toLowerCase().includes(value.toLowerCase().trim()))
      );
    } else {
      setFilteredList(list);
    }
  }, 300);

  const handleEnter = (target: HTMLInputElement) => {
    target.blur();
  };

  useEffect(() => {
    //
    //
    //
    // Check this line on Error rendering Depth
    setFilteredList(list);
  }, [list]);

  return {
    handleSearch,
    handleEnter,
    filteredList,
    searchText,
  };
};
