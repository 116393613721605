import dayjs from "dayjs";

import { ILivePerformanceItem } from "@/types";
import { CardEnum } from "@/types/slider";

import { mainApi } from "./main";

export const livePerformanceApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerformanceList: builder.query({
      query: (arg: CardEnum | null) => ({
        url: `/tasks`,
        method: "get",
      }),
      transformResponse: (response: ILivePerformanceItem[]) => {
        return response.sort((b, a) =>
          a.updated_at && b.updated_at
            ? dayjs(a.updated_at).valueOf() - dayjs(b.updated_at).valueOf()
            : 0
        );
      },
      providesTags: ["Performance"],
    }),
  }),
});

export const { useGetPerformanceListQuery } = livePerformanceApi;
export const invalidatePerformance = () => livePerformanceApi.util.invalidateTags(["Performance"]);
