import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { strategiesService } from "@/services";

import { RootState } from "./index";
import { IStrategyBase } from "../types/strategies";

interface StrategiesBackTestingState {
  isLoading: boolean;
  requested: boolean;
  results: IStrategyBase[];
}

const initialState: StrategiesBackTestingState = {
  results: [],
  isLoading: false,
  requested: false,
};

export const fetchBackTesting = createAsyncThunk(
  "strategies/strategiesBackTesting",
  async ({ key, start, end }: { key: string; start?: string; end?: string }) => {
    const res = await strategiesService.getBackTestingData(key, start, end);
    return res?.data;
  }
);

export const strategiesBackTestingSlice = createSlice({
  name: "strategiesBackTesting",
  initialState,
  reducers: {
    resetStrategieData: (state) => {
      state.results = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackTesting.fulfilled, (state, action) => {
        state.requested = true;
        state.results = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchBackTesting.pending, (state) => {
        state.isLoading = true;
        state.results = initialState.results;
      })
      .addCase(fetchBackTesting.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error.message);
      });
  },
});

export const { resetStrategieData } = strategiesBackTestingSlice.actions;

const state = (state: RootState) => state;

export const strategiesBackTestingState = createSelector(
  state,
  (state) => state.strategiesBackTesting
);

export default strategiesBackTestingSlice.reducer;
