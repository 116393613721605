import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, FooterLayout, Heading } from "@/components";
import { authService } from "@/services";

import styles from "./TermsConditions.module.scss";

interface IProps {
  autoscroll?: boolean;
}

export const PrivacyPolicy: FC<IProps> = ({ autoscroll }) => {
  const isUserLogined = authService.isLogined();
  const navigate = useNavigate();

  return (
    <FooterLayout showFooter={false} autoscroll={autoscroll}>
      <div className={`${styles.container} ${isUserLogined ? "" : styles.blackTheme}`}>
        <Heading type="h1" className={styles.heading}>
          Privacy Policy
        </Heading>
        <div className={styles.innerContent}>
          <p>
            Protecting your private information is our priority. This Statement of Privacy applies
            to www.OdinUltra.com, www.OdinUltra.ai and Big Data Federation, Inc. (“BDF”) and governs
            data collection and usage. For the purposes of this Privacy Policy, unless otherwise
            noted, all references to Big Data Federation, Inc. include www.OdinUltra.com,
            www.OdinUltra.ai and BDF. The BDF website is an Information site. By using the BDF
            website, you consent to the data practices described in this statement.
          </p>
          <br />
          <p>
            <b>Collection of your Personal Information</b>
          </p>
          <p>
            In order to better provide you with products and services offered on our Site, BDF may
            collect personally identifiable information, such as your:
          </p>
          <br />
          <ul>
            <li>First and Last Name</li>
            <li>E-mail Address</li>
            <li>Employer</li>
          </ul>
          <br />
          <p>
            We do not collect any personal information about you unless you voluntarily provide it
            to us. However, you may be required to provide certain personal information to us when
            you elect to use certain products or services available on the Site. These may include:
            (a) registering for an account on our Site; (b) sending us an email message. To wit, we
            will use your information for, but not limited to, communicating with you in relation to
            services and/or products you have requested from us. We also may gather additional
            personal or non-personal information in the future.
          </p>
          <br />
          <p>
            <b>Use of your Personal Information</b>
          </p>
          <p>
            BDF collects and uses your personal information to operate its website(s) and deliver
            the services you have requested.
          </p>
          <br />
          <p>
            BDF may also use your personally identifiable information to inform you of other
            products or services available from BDF and its affiliates
          </p>
          <br />
          <p>
            <b>Sharing Information with Third Parties</b>
          </p>
          <p>BDF does not sell, rent or lease its customer lists to third parties.</p>
          <br />
          <p>
            BDF may share data with trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for deliveries. All such
            third parties are prohibited from using your personal information except to provide
            these services to BDF, and they are required to maintain the confidentiality of your
            information.
          </p>
          <br />
          <p>
            BDF may disclose your personal information, without notice, if required to do so by law
            or in the good faith belief that such action is necessary to: (a) conform to the edicts
            of the law or comply with legal process served on BDF or the site; (b) protect and
            defend the rights or property of BDF; and/or (c) act under exigent circumstances to
            protect the personal safety of users of BDF, or the public.
          </p>
          <br />
          <p>
            <b>Tracking User Behavior</b>
          </p>
          <p>
            BDF may keep track of the websites and pages our users visit within BDF, in order to
            determine what BDF services are the most popular. This data is used to deliver
            customized content and advertising within BDF to customers whose behavior indicates that
            they are interested in a particular subject area.
          </p>
          <br />

          <p>
            <b>Automatically Collected Information</b>
          </p>
          <p>
            Information about your computer hardware and software may be automatically collected by
            BDF. This information can include: your IP address, browser type, domain names, access
            times and referring website addresses. This information is used for the operation of the
            service, to maintain quality of the service, and to provide general statistics regarding
            use of the BDF website.
          </p>
          <br />

          <p>
            <b>Use of Cookies</b>
          </p>
          <p>
            The BDF website may use "cookies" to help you personalize your online experience. A
            cookie is a text file that is placed on your hard disk by a web page server. Cookies
            cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely
            assigned to you, and can only be read by a web server in the domain that issued the
            cookie to you.
          </p>
          <br />
          <p>
            One of the primary purposes of cookies is to provide a convenience feature to save you
            time. The purpose of a cookie is to tell the Web server that you have returned to a
            specific page. For example, if you personalize BDF pages, or register with BDF site or
            services, a cookie helps BDF to recall your specific information on subsequent visits.
            This simplifies the process of recording your personal information, such as billing
            addresses, shipping addresses, and so on. When you return to the same BDF website, the
            information you previously provided can be retrieved, so you can easily use the BDF
            features that you customized.
          </p>
          <br />
          <p>
            You have the ability to accept or decline cookies. Most Web browsers automatically
            accept cookies, but you can usually modify your browser setting to decline cookies if
            you prefer. If you choose to decline cookies, you may not be able to fully experience
            the interactive features of the BDF services or websites you visit.
          </p>
          <br />

          <p>
            <b>Children Under Thirteen</b>
          </p>
          <p>
            BDF does not knowingly collect personally identifiable information from children under
            the age of thirteen. If you are under the age of thirteen, you must ask your parent or
            guardian for permission to use this website.
          </p>
          <br />

          <p>
            <b>E-mail Communications</b>
          </p>
          <p>
            From time to time, BDF may contact you via email for the purpose of providing
            announcements, promotional offers, alerts, confirmations, surveys, and/or other general
            communication.
          </p>
          <br />

          <p>
            <b>Changes to this Statement</b>
          </p>
          <p>
            BDF reserves the right to change this Privacy Policy from time to time. We will notify
            you about significant changes in the way we treat personal information by sending a
            notice to the primary email address specified in your account, by placing a prominent
            notice on our site, and/or by updating any privacy information on this page. Your
            continued use of the Site and/or Services available through this Site after such
            modifications will constitute your: (a) acknowledgment of the modified Privacy Policy;
            and (b) agreement to abide and be bound by that Policy.
          </p>
          <br />

          <p>
            <b>Contact Information</b>
          </p>
          <p>
            BDF welcomes your questions or comments regarding this Statement of Privacy. If you
            believe that BDF has not adhered to this Statement, please contact BDF at:
          </p>
          <br />

          <div>
            <p>Big Data Federation, Inc.</p>
            <p>2025 Gateway Place, Suite 650</p>
            <p>San Jose, California 95110</p>
          </div>
          <div className={styles.footerForm}>
            <p>
              Email Address: <a href="mailto:info@bigdatafed.com">info@bigdatafed.com</a>
            </p>
            <p>
              Telephone number: <a href="tel: +1 408 609 5037">+1 408 609 5037</a> |{" "}
              <a href="tel:+1 408 806 7951">+1 408 806 7951</a>
            </p>
            <p>Effective as of June 1, 2023</p>
          </div>
        </div>
        <div className={styles.buttonBlock}>
          <Button
            text="Go Back"
            type={isUserLogined ? "primary" : "white"}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </FooterLayout>
  );
};
