import React, { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import styles from "./ModalLayout.module.scss";

const modalNode = document.getElementById("app-modal");

interface IProps {
  children: React.ReactNode;
  show: boolean;
  onClose?: (() => void) | null;
  insertTo?: React.MutableRefObject<HTMLElement | null>;
}

export const ModalLayout: FC<IProps> = ({ children, show, onClose, insertTo }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (ev.key === "Escape" && onClose) onClose();
    };

    window.addEventListener("keydown", keydownHandler);

    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, [onClose]);

  const closeModalHandler = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (ev.target === itemRef.current && onClose) onClose();
  };

  if (!modalNode || !show) return null;

  if (insertTo?.current) {
    return createPortal(
      <div
        className={`${styles.container} ${styles.scoped}`}
        onClick={closeModalHandler}
        ref={itemRef}
      >
        {children}
      </div>,
      insertTo.current
    );
  }

  return createPortal(
    <div className={styles.container} onClick={closeModalHandler} ref={itemRef}>
      {children}
    </div>,
    modalNode
  );
};
