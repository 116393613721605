import { FC } from "react";

import { ReactComponent as InfoIcon } from "@images/icons/info.svg";

import styles from "./AlertMessage.module.scss";

interface IProps {
  name?: string;
  title: string;
  text: string;
  type: "warning" | "error" | "info";
  hasBottomSpace?: boolean;
}

export const AlertMessage: FC<IProps> = ({ name, title, text, type = "info", hasBottomSpace }) => {
  return (
    <div
      className={`${styles.container} ${hasBottomSpace ? styles.hasBottomSpace : ""}`}
      data-type={type}
    >
      <div className={styles.iconWrapper}>
        <InfoIcon />
      </div>
      <div className={styles.textGroup}>
        <p className={styles.title}>{title}</p>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};
