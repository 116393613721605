import { FC } from "react";

import styles from "./Chips.module.scss";

export type ChipsType = "primary" | "secondary" | "grayed-out" | "outline" | "success" | "error";

interface IProps {
  label?: string;
  size?: "medium" | "small" | "";
  type?: ChipsType;
}

export const Chips: FC<IProps> = ({ label, size = "default", type = "outline" }) => {
  if (!label) return null;
  return (
    <span className={styles.container} data-size={size} data-type={type}>
      {label}
    </span>
  );
};
