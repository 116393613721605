import { FC, Fragment, useMemo } from "react";

import { SideBarKeyEnum } from "@/constants";
import { faqDescriptionList } from "@/constants/faqDescription";
import { usePageInfo } from "@/hooks";
import { featurePermissions } from "@/utils";

import { InfoHeading } from "../Heading/Heading";

import styles from "./DesctiptionSection.module.scss";

const list = [
  {
    title: "Market Spider",
    description:
      "This is proprietary measure showing scores along 6 dimension in the US financial market; macro, liquidity, fundamentals, valuation, sentiment and technicals. Macro indicates overall health of the US economy using key economic indicators. Liquidity measures the change in the money supply for the US financial system. Fundamentals measures growth and acceleration of key company fundamentals. Valuation indicates composite valuation rank for major US indices. Sentiment indicates the rank percentile of AAII bull bear spread. Technicals shows rank of market momentum",
    featureKey: "chart-1",
  },
  {
    title: "Valuation",
    description: "This is provides the overall valuation for the major indices and sectors.",
    featureKey: "chart-2",
  },
  {
    title: "Total Market Put/Call Ratio Trend",
    description: "This is Put-Call options contracts ratio for the whole US equity market.",
    featureKey: "chart-3",
  },
  {
    title: "Best/Worst Performing",
    description:
      "This is provides real-time the “Best” and “Worst” performing tickers for each category based on market cap for any specific period.",
    featureKey: "chart-4",
  },
  {
    title: "Nominal Consumer Spending",
    description:
      "This is a proprietary real-time aggregation providing the total amount of money spent by individuals on goods and services, without adjusting for inflation or changes in purchasing power.",
    featureKey: "chart-5",
  },
];

export const DesctiptionSection: FC = () => {
  const { textInfo, video } = usePageInfo(SideBarKeyEnum.MARKET_VIEW);

  const listWithPermissions = useMemo(() => {
    return list.filter(({ featureKey }) => featurePermissions.isVisible(featureKey));
  }, [list]);

  return (
    <section className={styles.container}>
      <InfoHeading helpInfo={textInfo} tutorialLink={video} faqList={faqDescriptionList.marketView}>
        Description
      </InfoHeading>
      <div className={styles.inner}>
        {listWithPermissions.map(({ description, title }) => (
          <Fragment key={title}>
            <p className={styles.title}>{title}</p>
            <p className={styles.description}>{description}</p>
          </Fragment>
        ))}
        {/* <p className={styles.title}>Closest Market Pattern Matches</p>
        <p className={styles.description}>
          This is a proprietary real-time measure showing the market pattern closest matching the
          current market day using these 70+ indicators.
        </p> */}
      </div>
    </section>
  );
};
