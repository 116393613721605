import { FC } from "react";

import styles from "./InputTextItem.module.scss";

interface IProps {
  onChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string | null;
  noMarginBottom?: boolean;
  themed?: boolean;
  isReadonly?: boolean;
}

export const InputTextItem: FC<IProps> = ({
  name,
  value,
  label,
  placeholder,
  error,
  onChange,
  noMarginBottom,
  disabled = false,
  themed,
  isReadonly = false,
}) => {
  return (
    <div
      className={`${styles.container} ${noMarginBottom ? "" : styles.marginBottom} ${
        error ? styles.error : ""
      } ${themed ? styles.themed : ""}`}
      data-disabled={disabled}
      data-readonly={isReadonly}
    >
      <div className={styles.label}>{label}</div>
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {!!error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};
