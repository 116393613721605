import { authService } from "@/services";

import Image from "@images/info-white.svg";

export enum Notify {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

const DELAY = 5000;
const DEFAULT_CLASS_NAME = "odin-ultra";
const SIDE_BAR_MENU_ID = "side-bar-nav";

export class Notifications {
  containerNodeClassName: string;
  innerNodeClassName: string;
  notifCount = 0;
  notifContainer: HTMLDivElement | null = null;
  removeElement: (() => void) | null = null;

  constructor(nodeName: string) {
    this.containerNodeClassName = nodeName.concat("-notification-container");
    this.innerNodeClassName = nodeName.concat("-notification-inner");
  }

  createContainer() {
    if (this.notifContainer === null) {
      const isMobileView = window.matchMedia("(max-width: 991px)").matches;
      const sideBarMenu = document.getElementById(SIDE_BAR_MENU_ID);
      const container = document.createElement("div");
      container.classList.add(this.containerNodeClassName);

      if (this.notifCount === 0 && sideBarMenu) {
        const shift = isMobileView ? 0 : sideBarMenu.clientWidth / 2;
        container.setAttribute("style", `left: calc(50vw + ${shift})px`);
      }

      this.notifContainer = container;
    }

    return this.notifContainer;
  }

  createMessage(message: string, autoclose: boolean) {
    const element = document.createElement("div");
    const textContainer = document.createElement("p");
    const image = document.createElement("img");

    textContainer.innerText = message;
    element.classList.add(this.innerNodeClassName);

    image.src = Image;
    image.alt = "notify-icon";
    let timerID: NodeJS.Timeout | null = null;

    const removeItem = () => {
      const animationEndHandler = () => {
        element.remove();
      };
      element.classList.add("hide");
      element.addEventListener("animationend", animationEndHandler);
    };

    const startTimeout = () => {
      timerID = setTimeout(() => {
        if (this.notifCount > 0 && this.notifContainer) {
          element.removeEventListener("mouseenter", mouseOverHandler);
          element.removeEventListener("mouseleave", mouseLeaveHandler);
          removeItem();
          this.notifCount--;
          if (this.notifCount <= 0) {
            this.notifCount = 0;
          }
        }
      }, DELAY);
    };

    if (autoclose) startTimeout();

    function mouseLeaveHandler() {
      if (autoclose) startTimeout();
    }

    function mouseOverHandler() {
      if (timerID) {
        clearTimeout(timerID);
        element.addEventListener("mouseleave", mouseLeaveHandler);
      }
    }

    element.append(image, textContainer);

    this.removeElement = () => {
      if (timerID) clearTimeout(timerID);

      element.removeEventListener("mouseenter", mouseOverHandler);
      element.removeEventListener("mouseleave", mouseLeaveHandler);
      removeItem();
    };

    element.onclick = this.removeElement;

    element.addEventListener("mouseenter", mouseOverHandler);
    this.notifCount++;
    return element;
  }

  render(message: string, status: Notify, autoclose: boolean) {
    const container = this.createContainer();
    const isEmpty = container.firstChild === null;
    const notification = this.createMessage(message, autoclose);
    const body = document.body;

    notification.classList.add(`${this.innerNodeClassName}-${status}`);
    container.prepend(notification);

    if (isEmpty) {
      body.prepend(container);
    }
  }

  info(message: string, show = false, autoclose = true) {
    if (authService.isLogined() || show) this.render(message, Notify.INFO, autoclose);
  }

  success(message: string, show = false, autoclose = true) {
    if (authService.isLogined() || show) this.render(message, Notify.SUCCESS, autoclose);
  }

  error(message: string, show = false, autoclose = true) {
    if (authService.isLogined() || show) this.render(message, Notify.ERROR, autoclose);
  }

  warning(message: string, show = false, autoclose = true) {
    if (authService.isLogined() || show) this.render(message, Notify.WARNING, autoclose);
  }
}

export const notification = new Notifications(DEFAULT_CLASS_NAME);
