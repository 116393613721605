import { ItemType } from "@/components";

import { IPremission, IUserPermissions } from "./permission";
import { IBenchmark } from "./portfolio";

export type StrategyActionType = "HOLD" | "REQUIRED";

export interface IStrategyAction {
  type: StrategyActionType;
  description: string[] | null;
}

export interface IStrategy {
  id: number;
  name: string;
  portfolio_mapping: string | null;
  action: IStrategyAction;
}

export interface IStrategies {
  isLoading: boolean;
  data: ICommonStrategyData[] | null;
}

export interface IStrategiesResponse {
  strategies: IStrategy[];
}

export interface EquityLinePointInterface {
  Dates: string;
  EquityLine: number;
}

export interface QuarterlyStatsRowInterface {
  [key: string]: string | number;
}

export interface OverallStatsRowInterface {
  [key: string]: number;
}

export interface AnnualStatsRowInterface {
  [key: string]: string | number;
}

export interface Ticker {
  ticker?: string;
}

export interface IStartegyCard {
  id: number;
  key: string;
  name: string;
  config: INewStrategyData["config"];
  company_id: number | null;
  updated_at: string | null;
  created_at: string | null;
  user: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  };
  permissions?: IUserPermissions;
  show_on_home?: boolean;
}

export interface IStrategyData {
  id: number;
  name: string;
  config: INewStrategyData["config"];
  company_id: number;
  user_id: number;
  created_at: string | null;
  updated_at: string | null;
  permissions?: IUserPermissions;
  user?: IStrategyUserInfo;
  show_on_home?: boolean;
  description?: string;
}

export interface ICommonStrategyData {
  id: number;
  key: string;
  name: string;
  parameters: Ticker;
  type: StrategyTypeEnum[];
  updated_at: string;
  equity_line?: Record<string, number>;
  description?: string;
  strategy_type?: string;
}

export interface IBenchmarkMetric {
  value: string | null;
  benchmark?: string | null;
}

export interface IData<U> {
  equity: { [key: string]: Record<string, number | null> | null };
  metrics: Record<string, U>;
}

export interface IMetricData {
  metric: string;
  value?: string | null;
  strategy?: string | null;
  benchmark?: string | null;
}

export interface IBenchmarkData {
  strategy: number | null;
  benchmark: number | null;
}

export type IHistoryData = Record<string, number | string | null>;

export interface IDataPerformance {
  equity: Record<string, number | null> | Record<string, IBenchmarkData>;
  metrics: IMetricData[];
  positions?: IHistoryData[];
}

export interface IStrategyBase {
  key: string;
  data: IData<IBenchmarkMetric>;
  name: string;
  type: string[];
  parameters: Ticker;
}

export type StrategiesResponse = {
  [key: string]: ICommonStrategyData[];
};

export type StrategiesBackTestingDataResponse = IStrategyBase;

export enum StrategyTypeEnum {
  STRATEGY_BASE = "base",
  STRATEGY_OVERLAY = "overlay",
  INDICATOR = "indicator",
}

export type StrategyType = "core" | "overlay";
export type ConditionType = "entry" | "exit";
export type DirectionType = "short" | "long";
export type OperatorType = {
  value: string;
  isOperator: boolean;
  index: number;
  category?: string;
  isNumber?: boolean;
  numValue?: string;
  info?: string;
  userID?: number;
};
export type TradingInstrumentType = "stocks/etfs" | "baskets" | "options" | "cryptos";
export type TradingInstrumentConfigType =
  | "baskets"
  | "derivatives"
  | "etfs"
  | "equities"
  | "cryptos";
export type UpdateFrequencyType = "Daily" | "Weekly" | "Monthly" | "Quarterly";
export type RebalanceFrequencyType = "Daily" | "Weekly" | "Monthly" | "Quarterly";
export type WeightingScheme =
  | "Equal-Weight"
  | "Cap-Weight"
  | "Factor-Weight"
  | "Inverse-Volatility-Weight";
export type TradingBasketType = "custom-basket" | "sp500" | "nasdaq100" | "mid_cap" | "small_cap";
export type OptionType = "buy call" | "sell call" | "buy put" | "sell put";
export type SellFrequencyType = "daily" | "weekly" | "monthly";
export type UnderlyingType = "equities" | "etfs" | "index";
export type StrikeRatioType =
  | "ATM"
  | "XX percentage OTM"
  | "XX percentage ITM"
  | "XX STDEV OTM"
  | "XX STDEV ITM";

export type AllocationStyleType = "notional" | "valueBased";
export type ExitStyleType = "hold-to-maturity" | "entry-exit-signals";

export type InputFieldNames =
  | "maxDays"
  | "minDays"
  | "stopGain"
  | "stopLoss"
  | "ratio"
  | "percentage";

export type DerivativeOptionType = "options";

export interface IInputField {
  value: string;
  error: string;
}

export interface IConditionForm {
  category: string | null;
  indicator: string | null;
  formula: string | null;
  list: OperatorType[] | null;
  error: string | null;
}

export interface IEquitiesBaskets {
  basket: TradingBasketType | null;
  tickers: string[];
  weightScheme: WeightingScheme | null;
  updateFrequency: UpdateFrequencyType | null;
  rebalanceFrequency: RebalanceFrequencyType | null;
  advancedFilter: string | null;
  metricsList: IMetricIndicator[] | null;
  maxAllowedWeight: string | null;
}

export interface ITradingInstrument {
  type: TradingInstrumentType | null;
  equityBaskets: IEquitiesBaskets;
  options: IInstrumentOptionsType;
  futures: null;
}

export interface IInstrumentOptionsType {
  optionsType: OptionType;
  minDays: IInputField;
  maxDays: IInputField;
  stopGain: IInputField;
  stopLoss: IInputField;
  underlying: UnderlyingType;
  ticker: string | null;
  strikeRatioType: StrikeRatioType;
  ratio: IInputField;
  percentage: IInputField;
  allocationStyle: AllocationStyleType;
  exitStyle: ExitStyleType;
}

export interface TickerType {
  etfs: string | null;
  equities: string | null;
  index: string | null;
  basket: string | null;
  cryptos: string | null;
}

export type DateType = { start: string | null; end: string | null };
export type Description = {
  value: string | null;
  error: string | null;
};

export interface INewStartegyForm {
  name: string | null;
  type: StrategyType;
  ticker: TickerType;
  direction: DirectionType | null;
  error: string | null;
  activeCondition: ConditionType;
  date: DateType;
  conditions: {
    entry: IConditionForm;
    exit: IConditionForm;
  };
  isTemplate: boolean;
  tradingInstrument: ITradingInstrument;
  isChanged?: boolean;

  benchmark: string | null;
  benchmarkList: IBenchmark[] | null;
  description: Description;
}

export interface INewStrategyData {
  name: string | null;
  config: {
    type: StrategyType;
    direction?: DirectionType;
    trading_ticker?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    entry_condition?: string[] | null;
    exit_condition?: string[] | null;
    trading_instrument: TradingInstrumentConfigType | null;

    rebalance_freq?: RebalanceFrequencyType | null;
    trading_basket?: TradingBasketType | string | null;
    update_freq?: UpdateFrequencyType | null;
    weight_scheme?: WeightingScheme | null;
    advanced_filtering_condition?: string | null;
    max_aum_fraction?: number | null;
    is_custom_basket?: boolean;

    sell_frequency?: SellFrequencyType | null;
    underlying?: string;
    underlying_type?: UnderlyingType;
    strike_ratio?: string;
    derivative_type?: DerivativeOptionType;
    option_type?: OptionType;
    expiration_min_days?: number | null;
    expiration_max_days?: number | null;
    stop_gain?: number | null;
    stop_loss?: number | null;

    benchmark?: string | null;
    keep_until_maturity?: boolean;
    strike_bet?: AllocationStyleType;
  };
  description?: string;
  strategy_id?: number;
  is_template?: number;
}

export type NewStrategyConfig = INewStrategyData;

export type IUserStartegyDataResponse = Omit<IStrategyData, "permissions">;

export interface IIndicator {
  key: string;
  value: string;
  id?: number;
}

export interface IMetricIndicator {
  value: string;
  name: string;
}

export interface IIndicatorType {
  available: number;
  indicator: string | null;
  description?: string;
  id?: number;
}

export interface IConstructorErrors {
  name: string | null;
  entryStatus: string | null;
  exitStatus: string | null;
}

export interface IStartegyUser {
  id: number;
  email: string;
}

export type StrategyUserPermission = Omit<IPremission, "user" | "user_id"> & {
  id: number;
  strategy_id: number;
  company_id: number;
  created_at: string;
  updated_at: string;
  user: IStartegyUser;
};

export interface IStrategyUserInfo {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
}

export interface ITickersData {
  etfs: string[];
  equities: string[];
}

export interface ITickersKeyValueData {
  etfs: { key: string; value: string }[];
  equities: { key: string; value: string }[];
  basket: { key: string; value: string }[];
  index: { key: string; value: string }[];
  cryptos: { key: string; value: string }[];
}

export type StrategyPerformanceMetricType = {
  name: string;
  value: string | null;
  benchmark?: string | null;
}[];

export type StrategyPerformanceChartType = {
  name: string;
  data: (string | number | null)[][];
}[];
