import { FC, useEffect, useRef } from "react";

import styles from "./BacktestingEmptyResult.module.scss";

import BGImage from "@images/bg-fill.jpg";
import { ReactComponent as EmptyIcon } from "@images/empty-data.svg";

interface IProps {
  title: string | null;
  heading?: string;
  isVisible: boolean;
  info?: string | null;
}

export const BacktestingEmptyResult: FC<IProps> = ({
  title,
  isVisible,
  heading = "Modeling result",
  info = "Please, change the configuration and try again.",
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && itemRef.current) {
      itemRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [isVisible]);

  if (!isVisible || !title) return null;

  return (
    <section className={styles.emptyResultSection} ref={itemRef}>
      {heading ? <h2 className={styles.heading}>{heading}</h2> : null}
      <div className={styles.inner} style={{ backgroundImage: `url(${BGImage})` }}>
        <div className={styles.iconContainer}>
          <EmptyIcon />
        </div>
        <div className={styles.textSection}>
          <p className={styles.title}>{title}</p>
          <p className={styles.text}>{info}</p>
        </div>
      </div>
    </section>
  );
};
