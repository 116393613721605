import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  BacktestingEmptyResult,
  Button,
  Confirm,
  DatePickerRange,
  EmptySelected,
  ExtendedColumns,
  PerformanceResult,
  TooltipWrapper,
  UploadCSVForm,
} from "@/components";

import { AllocationCSVItem } from "./AllocationCSVItem/AllocationCSVItem";
import { useAllocationOptimizer } from "./hooks/useAllocationOptimizer";
import { UploadDataWithInfo } from "./UploadDataWithInfo/UploadDataWithInfo";

import styles from "./AllocationOptimizer.module.scss";

import { ReactComponent as EmptySelectedIcon } from "@images/empty-select.svg";
import { ReactComponent as EyeIcon } from "@images/eye.svg";

const helpText = [
  "1. At least it needs a column named 'ticker' and one of 'allocation' or 'share'.",
  "2. 'allocation' values should be in percent.",
  "3. 'share' values should be integer values.",
];

export const AllocationOptimizer: FC = () => {
  const navigate = useNavigate();
  const {
    removeCSVHandler,
    uploadCSVHandler,
    selectCSVHandler,
    selectDateHandler,

    calculateHandler,
    cancelHandler,
    refCallback,

    csvList,
    selectedCSV,
    isCSVListLoading,
    isCalculationResultEmpty,
    selectedCSVCreatedDate,
    isSameConfigData,
    fileName,
    isDataReady,
    progress,
    uploading,

    chartData,
    tableData,
    columns,
    date,
  } = useAllocationOptimizer();

  const CustomLabel = () => {
    const navigateHandle = () => {
      if (selectedCSV)
        navigate(
          `/user-data/details/${selectedCSV.key}?type=allocations&name=${encodeURIComponent(
            selectedCSV.value
          )}`
        );
    };

    return (
      <div className={styles.labelWrapper}>
        <span>Select Allocations CSV</span>
        {selectedCSV ? (
          <span className={styles.link} onClick={navigateHandle}>
            <span className={styles.iconContainer}>
              <EyeIcon />
            </span>
            Preview
          </span>
        ) : null}
      </div>
    );
  };

  const columnsWithTooltip = useMemo((): ExtendedColumns => {
    return columns.map((col, idx) => ({
      ...col,
      Header: (v: any) => (
        <TooltipWrapper text={idx > 0 ? (col.Header as string) : ""} truncateContent>
          {col.Header as string}
        </TooltipWrapper>
      ),
      id: `${idx}`,
    }));
  }, [columns]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.content}>
          {progress ? (
            <div className={styles.progressContainer}>
              <Confirm
                onCancel={cancelHandler}
                progress={progress}
                text="Calculating allocations is in progress. This may take a few minutes. You can wait a little bit to view the result or stop to cancel the calculation."
                type="calculate"
              />
            </div>
          ) : null}
          <form
            className={`${styles.userForm} ${progress ? styles.disabled : ""}`}
            onSubmit={(ev) => ev.preventDefault()}
          >
            {" "}
            <div className={styles.rowGroup}>
              <UploadDataWithInfo
                title="Upload CSV data file"
                helpText={helpText}
                helpTitle="The CSV file data format"
                onUpload={uploadCSVHandler}
                disabled={uploading}
              />
            </div>
            <div className={styles.rowGroup}>
              <UploadCSVForm
                csvList={csvList}
                isLoading={isCSVListLoading || uploading}
                canDelete={!!selectedCSV}
                selectedCSVFile={selectedCSV}
                deletingFileName={fileName}
                onSelectOneCSVHandler={selectCSVHandler}
                onRemoveCSVHandler={removeCSVHandler}
                CustomLabel={CustomLabel}
                isMultiSelect={false}
                withInfo
              />
            </div>
            {selectedCSV ? (
              <div className={styles.selectedItemContainer}>
                <p>Selected Allocation</p>
                <AllocationCSVItem name={selectedCSV.value} created={selectedCSVCreatedDate} />
              </div>
            ) : (
              <EmptySelected
                Icon={EmptySelectedIcon}
                title="No allocation/share is picked yet."
                text="Please select an allocation/share CSV file."
              />
            )}
            <div className={styles.rowGroup}>
              <DatePickerRange
                onSelect={selectDateHandler}
                label="Select Date Interval"
                date={date || undefined}
                disabled={false}
              />
              <div className={styles.rowGroup}>
                <div style={{ width: "100%" }}></div>
                <div className={styles.buttonWrapper}>
                  <Button
                    text="Start Calculation"
                    onClick={calculateHandler}
                    disabled={!selectedCSV || isSameConfigData}
                  />
                </div>
              </div>
            </div>
            <BacktestingEmptyResult
              isVisible={isCalculationResultEmpty}
              title="No Data was calculated with this allocation configuration."
              heading="Allocations Result"
            />
          </form>
        </div>
        {!isCalculationResultEmpty ? (
          <section
            className={`${styles.resultSection} ${
              isCalculationResultEmpty ? styles.marginTop : ""
            }`}
            ref={refCallback}
          >
            <PerformanceResult
              heading="Allocations"
              isPreviewReady={isDataReady}
              isEmptyResult={!isCalculationResultEmpty}
              columns={columnsWithTooltip}
              tableData={tableData}
              chartData={chartData}
            />
          </section>
        ) : null}
      </div>
    </div>
  );
};
