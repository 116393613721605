import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ModalStatus, ROUTES, SideBarKeyEnum } from "@/constants";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import { strategiesService } from "@/services";
import { invalidatePerformance } from "@/services/apiQuery/livePerformance";
import {
  fetchDeleteStrategy,
  initList,
  selectMyStrategies,
  setBasketTickersList,
} from "@/store/strategies/my_strategies";
import {
  setErrors,
  setActiveTab,
  resetStrategy,
  strategiesState,
  resetStrategyForm,
  fetchCreateStrategy,
  fetchUpdateStrategy,
  fetchBenchmarksList,
  setDescription,
  getStrategyLivePerformanceAsync,
} from "@/store/strategies/strategies";
import { IDataPerformance, INewStartegyForm, IStrategyBase } from "@/types";
import {
  isFormTouched,
  notification,
  prepareEditForm,
  prepareNewStartegyData,
  validateForm,
  validateFormula,
  validateStrategyName,
} from "@/utils";

import { useDownloadLivePositions } from "./useDawnloadLivePositions";
import { useDownloadHistorical } from "./useDownloadHistorical";
import { useGetData } from "./useGetData";
import { usePreviewModelingResult } from "./usePreviewModelingResult";

type IPayloadData = {
  result:
    | IStrategyBase[]
    | IDataPerformance
    | {
        task_id: string;
        message: string;
      };
};

interface IModal {
  save: boolean;
  remove: boolean;
  live: boolean;
  modeling: ModalStatus | null;
}

export const useCreateStrategy = (isEditMode?: boolean) => {
  const { newStartegyForm, historical, modelingProgress } = useAppSelector(strategiesState);
  const { list } = useAppSelector(selectMyStrategies);
  const [isSavingData, setIsSavingData] = useState(false);
  const [showModal, setShowModal] = useState<IModal>({
    save: false,
    remove: false,
    live: false,
    modeling: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { strategyId } = useParams();
  const sectionRef = useRef<{ result: HTMLElement | null; empty: HTMLElement | null }>({
    result: null,
    empty: null,
  });
  const isDefaultMemorizedRef = useRef<boolean>(false);
  const [formPrevState, setFormPrevState] = useState<INewStartegyForm | null>(null);
  const { downloadCSV, progress } = useDownloadHistorical();
  const [downloadLivePositionSCV, livePositionProgress, cancel] = useDownloadLivePositions();
  const {
    tradingInstrument: { type },
  } = newStartegyForm;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    chartData,
    tableData,
    columns,
    noData,
    isPreviewReady,
    isModelingPerformance,
    getEmptyResultTitle,
    getEmptyResultInfo,
  } = usePreviewModelingResult();

  const { isEmpty, isLoadingData, originalStrategyName } = useGetData({
    isEditMode,
    strategyId,
    isPreviewReady,
  });

  const strategyName = searchParams.get("name");
  const hasScheduled = searchParams.get("scheduled");
  const hasPerformance = searchParams.get("has_performance");

  const descriptionError = newStartegyForm.description.error !== null;

  const [canCreate, canPreview] = useMemo(() => validateForm(newStartegyForm), [newStartegyForm]);

  const canSaveOrCreate = useMemo(() => {
    if (isFormTouched(newStartegyForm, formPrevState)) return canCreate;
    return false;
  }, [formPrevState, newStartegyForm, canCreate]);

  const isVisibleLivePositions = formPrevState?.tradingInstrument.type === type && isEditMode;

  const { textInfo, video } = usePageInfo(SideBarKeyEnum.SINGLE_STRATEGY);

  const backHandler = useCallback(() => {
    navigate(`${ROUTES.modelingPortfolioBuilder.path}?current_tab=Single Strategy`);
    strategiesService.abort.abort();
  }, [strategiesService.abort]);

  const selectTabHandler = useCallback((key: string) => {
    dispatch(setActiveTab(key));
  }, []);

  const confirmModelingTestHandle = useCallback(() => {
    const strategyID = Number(strategyId);
    const { config, strategy_id, name } = prepareNewStartegyData(newStartegyForm, strategyID);
    if (newStartegyForm.benchmark) config.benchmark = newStartegyForm.benchmark;
    setShowModal((prev) => ({ ...prev, modeling: "progress" }));
    dispatch(getStrategyLivePerformanceAsync({ data: { config, strategy_id, name } }))
      .then((data) => {
        const { payload } = data;
        const result = (payload as IPayloadData).result;

        // removing task_id from URL
        if (result && "task_id" in result) {
          searchParams.set("task_id", result.task_id);
          searchParams.set("scheduled", "true");
          searchParams.delete("has_performance");
          name && searchParams.set("name", name);
          dispatch(invalidatePerformance());
          setTimeout(
            () => notification.success("The performance model is successful started."),
            500
          );
        } else if (result && "message" in result) {
          searchParams.set("scheduled", "true");
        } else if (result && !("task_id" in result) && !("message" in result)) {
          searchParams.delete("scheduled");
          searchParams.set("has_performance", "true");
        }
        setShowModal((prev) => ({ ...prev, modeling: null }));
        setSearchParams(searchParams);
      })
      .catch(() => {
        notification.warning(
          "Something went wrong during model generation. Please try again later."
        );
      })
      .finally(() => {
        setShowModal((prev) => ({ ...prev, modeling: null }));
      });
  }, [newStartegyForm, strategyId]);

  const cancelModelingHandle = () => {
    setShowModal((prev) => ({ ...prev, modeling: null }));
  };

  const modelingTestHandle = () => {
    setShowModal((prev) => ({ ...prev, modeling: "starting" }));
  };

  const removeHandler = () => {
    setShowModal((prev) => ({ ...prev, remove: true }));
  };

  const cancelHandler = () => {
    setShowModal((prev) => ({ ...prev, remove: false }));
  };

  const cancelSaveHandler = () => {
    setShowModal((prev) => ({ ...prev, save: false }));
  };

  const cancelLivePositionHandler = () => {
    setShowModal((prev) => ({ ...prev, live: false }));
    cancel.abort();
  };

  const confirmRemoveHandler = () => {
    if (strategyId)
      dispatch(fetchDeleteStrategy(Number(strategyId))).then(() => {
        navigate(`/modeling/portfolio-builder?current_tab=Single Strategy`);
      });
    setIsSavingData(true);
    setShowModal((prev) => ({ ...prev, remove: false }));
  };

  const downloadHistoricalHandler = () => {
    const fileName = newStartegyForm.name
      ? `${newStartegyForm.name}_strategy_historical`
      : `Strategy_${strategyId}_historical`;
    if (historical) downloadCSV(fileName, historical);
  };

  const confirmLivePositionHandler = () => {
    if (strategyId) {
      const fileName = newStartegyForm.name;
      downloadLivePositionSCV(Number(strategyId), fileName).then(() => {
        setShowModal((prev) => ({ ...prev, live: false }));
      });
    }
    setShowModal((prev) => ({ ...prev, live: true }));
  };

  const confirmSaveStrategyHandler = useCallback(() => {
    const error = validateStrategyName(newStartegyForm.name);
    const entryStatus = validateFormula(newStartegyForm.conditions.entry.formula);
    const exitStatus = validateFormula(newStartegyForm.conditions.exit.formula);
    if (!entryStatus && !exitStatus && !error) {
      setIsSavingData(true);
      // update existing strategy
      if (isEditMode) {
        const id = Number.isInteger(Number(strategyId)) ? Number(strategyId) : null;
        dispatch(fetchUpdateStrategy({ form: newStartegyForm, id })).then(() => {
          dispatch(initList());
          dispatch(resetStrategyForm());
          dispatch(setDescription(newStartegyForm.description.value || ""));
          setIsSavingData(false);
        });
        setShowModal((prev) => ({ ...prev, save: false }));
      }
      // create new strategy
      else {
        dispatch(fetchCreateStrategy(newStartegyForm)).then(() => {
          dispatch(initList());
          dispatch(resetStrategyForm());
          dispatch(setBasketTickersList([]));
          dispatch(setDescription(newStartegyForm.description.value || ""));
          setIsSavingData(false);
        });
      }
    } else {
      dispatch(setErrors({ name: error, entryStatus, exitStatus }));
    }

    setFormPrevState(newStartegyForm);
    setShowModal((prev) => ({ ...prev, save: false }));
  }, [newStartegyForm, isEditMode, strategyId]);

  const saveStrategyHandler = () => {
    if (isEditMode) setShowModal((prev) => ({ ...prev, save: true }));
    else confirmSaveStrategyHandler();
  };

  const isEmptyBacktestingResult = useMemo(
    () => !chartData.some((i) => i.data.length > 0) && !tableData.length && isPreviewReady,
    [tableData, chartData, isPreviewReady]
  );

  const isEmptyResultVisible = useMemo(() => {
    if (hasScheduled === "true") return true;
    if (hasPerformance === "true") return isModelingPerformance || noData;
    return false;
  }, [noData, isModelingPerformance, hasPerformance, hasScheduled]);

  useEffect(() => {
    if (isEmptyBacktestingResult) {
      sectionRef.current.empty?.scrollIntoView({ block: "center", behavior: "smooth" });
    } else if (isPreviewReady && hasPerformance === "true") {
      sectionRef.current.result?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [isPreviewReady, isEmptyBacktestingResult, hasPerformance]);

  useEffect(() => {
    if (newStartegyForm.benchmarkList === null) {
      dispatch(fetchBenchmarksList());
    }
  }, [newStartegyForm.benchmarkList]);

  useEffect(() => {
    if (list?.length && isDefaultMemorizedRef.current === false) {
      const strategy = list.find((s) => s.id === Number(strategyId));
      const { metricsList, tickers } = newStartegyForm.tradingInstrument.equityBaskets;
      if (strategy && metricsList) {
        const form = prepareEditForm(strategy, metricsList);
        setFormPrevState(form);
        dispatch(setBasketTickersList(tickers));
        isDefaultMemorizedRef.current = true;
      }
    }
  }, [list, strategyId, newStartegyForm]);

  useEffect(() => {
    // reset strategy data after unmounting component
    return () => {
      dispatch(resetStrategy());
    };
  }, []);

  return {
    isEmptyBacktestingResult,
    isVisibleLivePositions,
    originalStrategyName,
    livePositionProgress,
    descriptionError,
    isEmptyResultVisible,
    isPreviewLoading: false,
    modelingProgress,
    canSaveOrCreate,
    newStartegyForm,
    isPreviewReady,
    isLoadingData,
    strategyName,
    isSavingData,
    canPreview,
    sectionRef,
    strategyId,
    historical,
    showModal,
    chartData,
    tableData,
    progress,
    isEmpty,
    columns,

    getEmptyResultTitle,
    getEmptyResultInfo,

    confirmSaveStrategyHandler,
    confirmLivePositionHandler,
    downloadHistoricalHandler,
    cancelLivePositionHandler,
    confirmModelingTestHandle,
    cancelModelingHandle,
    confirmRemoveHandler,
    saveStrategyHandler,
    modelingTestHandle,
    cancelSaveHandler,
    selectTabHandler,
    cancelHandler,
    removeHandler,
    backHandler,

    textInfo,
    video,
  };
};
