import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "@/hooks/redux";
import {
  invalidatePerformance,
  useGetPerformanceListQuery,
} from "@/services/apiQuery/livePerformance";
import { fetchMarkAsReadPerformance } from "@/store/performance";

const TIMEOUT_DELAY = 500;

export const useMarkAsReadPerformance = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { data: list } = useGetPerformanceListQuery(null);

  const taskID = searchParams.get("task_id");
  const scheduled = searchParams.get("scheduled");

  useEffect(() => {
    const foundItem = list?.find((i) => i.task_id === taskID && i.seen === 0);

    if (
      foundItem &&
      (foundItem.status === "SUCCESS" || foundItem.status === "FAILURE") &&
      scheduled === null
    ) {
      setTimeout(() => {
        foundItem.task_id &&
          dispatch(fetchMarkAsReadPerformance(foundItem.task_id)).then(() => {
            dispatch(invalidatePerformance());
          });
      }, TIMEOUT_DELAY);
    }
  }, [taskID, list, scheduled]);
};
