import { FC, memo } from "react";

import { InputItem, Dropdown, RadioList, DatePickerRange, AlertMessage } from "@/components";
import { InputTextItem } from "@/components/InputItem/InputTextItem";
import { directionList, instrumentList, labels, toolTipHelperList } from "@/constants";
import { TradingInstrumentType } from "@/types";

import { useCommonForm } from "../../hooks/useCommonForm";
import { BasketForm } from "../BasketForm/BasketForm";
import { BenchmarkForm } from "../BenchmarkForm/BenchmarkForm";
import { CryptosForm } from "../CryptosForm/CryptosForm";
import { DerivatevesForm } from "../DerivatevesForm/DerivatevesForm";
import { StockETFsForm } from "../StockETFsForm/StockETFsForm";

import styles from "./CreateBaseForm.module.scss";

interface IProps {
  isTemplate?: boolean;
  description?: string;
  isModified: boolean;
}

export const CreateBaseForm: FC<IProps> = memo(({ isTemplate, description, isModified }) => {
  const {
    changeNameHandler,
    selectDateHandler,
    radioChangeHandler,
    selectDirectionHandler,
    selectInstrumentHandler,
    selectTickerHandler,
    changeDescriptionHandle,

    descriptionValue,
    descriptionError,
    selectedInstrument,
    selectedTicker,
    selectedDirection,
    disableOptions,
    isLoadingTickers,

    tradingInstrumentType,
    newStrategyFormError,
    newStartegyFormName,
    newStartegyFormDate,
    newStartegyFormType,
    tradingTickers,
    combinedTickersList,
  } = useCommonForm();

  const stockEtfsOptionsProps = {
    selectedTicker,
    isLoadingTickers,
    directionList,
    selectedDirection,
    list: combinedTickersList,
    disableOptions,
    selectDirectionHandler,
    selectTickerHandler,
  };

  const baseProps = {
    tradingTickersList: tradingTickers,
    isLoading: isLoadingTickers,
  };

  return (
    <div className={styles.container}>
      {isTemplate ? (
        <AlertMessage
          title="Template"
          text={`This is a template strategy. Only "Date Interval" is modifiable by user. To modify other fields, copy the strategy to "My Strategies" portion.`}
          type="info"
          hasBottomSpace
        />
      ) : null}
      {isModified ? (
        <AlertMessage
          title="Note"
          text={`The strategy form has been changed. Click "SAVE" to preserve your changes and avoid losing the configuration.`}
          type="warning"
          hasBottomSpace
        />
      ) : null}
      <div className={`${styles.row} ${styles.noSpace}`}>
        <div className={styles.inputWrapper}>
          <InputItem
            label="Strategy Name"
            placeholder="Strategy Name"
            name="strategyName"
            type="input"
            onChange={changeNameHandler}
            error={newStrategyFormError}
            value={newStartegyFormName || ""}
            tooltipHelp={toolTipHelperList.strategyName}
            isReadonly={isTemplate}
            isRequired
          />
        </div>

        <div className={styles.itemWrapper}>
          <Dropdown<"instrument", TradingInstrumentType>
            list={instrumentList}
            onSelect={selectInstrumentHandler}
            selected={selectedInstrument}
            placeholder="Select Trading Instrument"
            name="instrument"
            label="Trading Instrument"
            isReadonly={isTemplate}
          />
        </div>
      </div>

      {tradingInstrumentType === "stocks/etfs" && (
        <StockETFsForm
          {...stockEtfsOptionsProps}
          disableOptions={stockEtfsOptionsProps.disableOptions}
          isReadonly={isTemplate}
        />
      )}
      {tradingInstrumentType === "cryptos" && (
        <CryptosForm {...baseProps} isReadonly={isTemplate} />
      )}
      {tradingInstrumentType === "baskets" && <BasketForm {...baseProps} isReadonly={isTemplate} />}
      {tradingInstrumentType === "options" && (
        <DerivatevesForm {...baseProps} isReadonly={isTemplate} />
      )}

      <div className={`${styles.row} ${disableOptions ? styles.disabled : ""}`}>
        <div className={styles.itemWrapper}>
          <DatePickerRange
            onSelect={selectDateHandler}
            label="Date Interval"
            date={newStartegyFormDate}
            disabled={disableOptions}
          />
        </div>
        <div className={styles.itemWrapper}>
          <p>Strategy Type</p>
          <div className={styles.radioGroup}>
            <RadioList
              labels={labels}
              name="type"
              onChange={radioChangeHandler}
              value={newStartegyFormType}
              disabled={disableOptions || isTemplate}
            />
          </div>
        </div>
        <div className={styles.itemWrapper}></div>
      </div>
      <div className={`${styles.row} ${styles.noSpace}`}>
        <div className={styles.inputWrapper}>
          <InputTextItem
            label="Description"
            placeholder="Strategy short description"
            name="strategyDescription"
            error={descriptionError}
            value={description || descriptionValue || ""}
            isReadonly={isTemplate}
            onChange={changeDescriptionHandle}
          />
        </div>
      </div>

      <BenchmarkForm isReadonly={isTemplate} />
    </div>
  );
});
