import dayjs from "dayjs";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { DATE_ISO, ModalStatus, ROUTES, SideBarKeyEnum } from "@/constants";
import { useAppDispatch, useAppSelector, usePageInfo } from "@/hooks";
import { invalidatePerformance } from "@/services/apiQuery/livePerformance";
import {
  strategiesState,
  fetchBenchmarksList,
  setActiveStrategy,
  fetchMetricsList,
  setActiveTab,
  getStrategyLivePerformanceAsync,
  resetStrategy,
  getStrategyPerformanceResult,
  setDate,
} from "@/store/strategies/strategies";
import {
  fetchTemplateStrategiesData,
  selectTemplateStrategies,
} from "@/store/strategies/template_strategies";
import { IDataPerformance, IStrategyBase, IStrategyData } from "@/types";
import { notification, prepareNewStartegyData } from "@/utils";

import { usePreviewModelingResult } from "./usePreviewModelingResult";

type IPayloadData = {
  result:
    | IStrategyBase[]
    | IDataPerformance
    | {
        task_id: string;
        message: string;
      };
};

export const useTemplatePreview = () => {
  const { newStartegyForm, historical, modelingProgress } = useAppSelector(strategiesState);
  const { list } = useAppSelector(selectTemplateStrategies);
  const [showModal, setShowModal] = useState<ModalStatus | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [updatePage, setUpdatePage] = useState(false);
  const { templateId } = useParams();
  const [performanceLoadingStatus, setPerformanceLoadingStatus] = useState<boolean>(false);
  const sectionRef = useRef<{ result: HTMLElement | null; empty: HTMLElement | null }>({
    result: null,
    empty: null,
  });

  const hasResultRequested = useRef(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    chartData,
    tableData,
    columns,
    noData,
    dateRange,
    isPreviewReady,
    isModelingPerformance,
    getEmptyResultTitle,
    getEmptyResultInfo,
  } = usePreviewModelingResult(true);

  const isEmpty = useMemo(() => {
    if (!list || performanceLoadingStatus) return null;
    const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
    return list && templateId && foundStrategy ? false : true;
  }, [list, templateId, updatePage, isPreviewReady, performanceLoadingStatus]);

  const strategyName = searchParams.get("name");
  const hasScheduled = searchParams.get("scheduled");
  const hasPerformance = searchParams.get("has_performance");
  const taskID = searchParams.get("task_id");

  const { textInfo, video } = usePageInfo(SideBarKeyEnum.SINGLE_STRATEGY);

  const backHandler = useCallback(() => {
    navigate(`${ROUTES.modelingPortfolioBuilder.path}?current_tab=Single Strategy`);
  }, []);

  const selectTabHandler = useCallback((key: string) => {
    dispatch(setActiveTab(key));
  }, []);

  const cancelModelingHandle = () => {
    setShowModal(null);
  };

  const modelingTestHandle = () => {
    setShowModal("starting");
  };

  const confirmModelingHandle = useCallback(() => {
    const strategyID = Number(templateId);
    const { config, strategy_id, name } = prepareNewStartegyData(newStartegyForm, strategyID);
    if (newStartegyForm.benchmark) config.benchmark = newStartegyForm.benchmark;
    setShowModal("progress");
    searchParams.delete("has_performance");
    dispatch(
      getStrategyLivePerformanceAsync({ data: { config, strategy_id, name, is_template: 1 } })
    )
      .then((data) => {
        const { payload } = data;
        const result = (payload as IPayloadData).result;

        // removing task_id from URL
        hasResultRequested.current = false;

        if (result && "task_id" in result) {
          searchParams.set("task_id", result.task_id);
          searchParams.set("scheduled", "true");
          searchParams.delete("has_performance");
          name && searchParams.set("name", name);
          dispatch(invalidatePerformance());
          setTimeout(
            () => notification.success("The performance model is successful started."),
            500
          );
        } else if (result && "message" in result) {
          searchParams.set("scheduled", "true");
        } else if (result && !("task_id" in result) && !("message" in result)) {
          searchParams.delete("scheduled");
          searchParams.set("has_performance", "true");
        }
        setShowModal(null);
        setSearchParams(searchParams);
      })
      .catch(() => {
        notification.warning(
          "Something went wrong during model generation. Please try again later."
        );
      })
      .finally(() => {
        setShowModal(null);
      });
  }, [newStartegyForm, templateId]);

  const isEmptyResultVisible = useMemo(() => {
    if (hasScheduled === "true") return true;
    if (hasPerformance === "true") return isModelingPerformance || noData;
    return false;
  }, [noData, isModelingPerformance, hasPerformance, hasScheduled]);

  const strategyDescription = useMemo(() => {
    if (list && templateId) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
      return foundStrategy ? foundStrategy.description : "";
    }
  }, [list, templateId]);

  useEffect(() => {
    if (isEmptyResultVisible) {
      sectionRef.current.empty?.scrollIntoView({ block: "center", behavior: "smooth" });
    } else if (isPreviewReady && hasPerformance === "true") {
      sectionRef.current.result?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [isPreviewReady, isEmptyResultVisible, hasPerformance]);

  useEffect(() => {
    if (newStartegyForm.benchmarkList === null) {
      dispatch(fetchBenchmarksList());
    }
  }, [newStartegyForm.benchmarkList]);

  useLayoutEffect(() => {
    let activeStrategy: IStrategyData | null = null;
    if (list && templateId) {
      const foundStrategy = list.find((strategy) => strategy.id === Number(templateId));
      if (foundStrategy) {
        activeStrategy = foundStrategy;
      }
    }
    if (!list) {
      dispatch(fetchTemplateStrategiesData());
    }
    if (activeStrategy) dispatch(setActiveStrategy(activeStrategy));
    dispatch(fetchMetricsList()).then(() => {
      activeStrategy && dispatch(setActiveStrategy(activeStrategy));
    });
  }, [list, templateId]);

  useEffect(() => {
    // reset strategy data after unmounting component
    return () => {
      dispatch(resetStrategy());
    };
  }, []);

  useLayoutEffect(() => {
    if (
      taskID &&
      hasPerformance === "true" &&
      !isModelingPerformance &&
      hasResultRequested.current === false
    ) {
      setPerformanceLoadingStatus(true);
      dispatch(getStrategyPerformanceResult(taskID)).then(() => {
        dispatch(
          setDate({
            start: dateRange.start ? dayjs(dateRange.start).format(DATE_ISO) : null,
            end: dateRange.end ? dayjs(dateRange.end).format(DATE_ISO) : null,
          })
        );
        setTimeout(() => setPerformanceLoadingStatus(false), 500);
        hasResultRequested.current = true;
      });
    }
  }, [taskID, hasPerformance, isModelingPerformance, dateRange]);

  // update page after changing taskID
  useLayoutEffect(() => {
    if (taskID) {
      setUpdatePage(true);
      hasResultRequested.current = false;
      setTimeout(() => setUpdatePage(false), 1000);
    }
  }, [taskID]);

  return {
    isEmptyResultVisible,
    strategyDescription,
    modelingProgress,
    newStartegyForm,
    isPreviewReady,
    strategyName,
    sectionRef,
    historical,
    showModal,
    chartData,
    tableData,
    isEmpty,
    columns,

    getEmptyResultTitle,
    getEmptyResultInfo,

    cancelModelingHandle,
    confirmModelingHandle,
    modelingTestHandle,
    selectTabHandler,
    backHandler,

    textInfo,
    video,
  };
};
