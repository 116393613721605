import dayjs from "dayjs";
import { FC, Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

import { DATE_ISO_TIME, ROUTES } from "@/constants";
import { useOuterClick } from "@/hooks";
import { ILivePerformanceItem } from "@/types";

import { ITaskInfo, usePerformanceLiveWatcher } from "./hooks/usePerformanceLiveWatcher";
import { Chips } from "../Chips/Chips";
import { Loader } from "../Loader/Loader";

import styles from "./LiveProcessing.module.scss";

import { ReactComponent as DoneIcon } from "@images/check-one-filled.svg";

interface IProps {
  name?: string;
  collapsed: boolean;
}

const REF_CONTAINER = document.querySelector("#processing-sidebar");

export const LiveProcessing: FC<IProps> = ({ collapsed }) => {
  const [position, setPosition] = useState<{ bottom: number; left: number } | null>(null);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [itemRef] = useOuterClick(setToggle, false, "models-list");

  const { list, itemsInfo, isLoading } = usePerformanceLiveWatcher();

  const toggleHandle = (state?: boolean) => {
    if (state === undefined) setToggle((prev) => !prev);
    else setToggle(state);
  };

  const actionHandle = useCallback((item: ILivePerformanceItem) => {
    if (item.meta.type === "portfolio") navigate("/");

    if (item.meta.type === "strategy" && item.meta.strategy_id)
      navigate(
        `${ROUTES.strategiesBuilder.path}/edit/${item.meta.strategy_id}?name=${encodeURIComponent(
          item.meta.name || "#"
        )}&task_id=${item.task_id}&has_performance=true`
      );
    if (Boolean(item.meta.is_template) && item.meta.type === "strategy")
      navigate(
        `${ROUTES.strategiesBuilder.path}/template/${
          item.meta.strategy_id
        }?name=${encodeURIComponent(item.meta.name || "#")}&task_id=${
          item.task_id
        }&has_performance=true`
      );
    if (item.meta.type === "strategy" && item.meta.strategy_id === null) {
      navigate(
        `${ROUTES.strategiesBuilder.path}/new?name=${encodeURIComponent(
          item.meta.name || "#"
        )}&task_id=${item.task_id}&has_performance=true`
      );
    }
  }, []);

  useEffect(() => {
    if (itemRef.current) {
      const { bottom, right } = itemRef.current.getBoundingClientRect();
      const left = collapsed ? right + 16 : right + 28;
      setPosition({ bottom: window.innerHeight - bottom, left });
    }
  }, [collapsed, toggle]);

  if (
    itemsInfo === null ||
    (itemsInfo.hasUnreadItems === false && itemsInfo.hasRunningItems === false)
  )
    return null;

  return (
    <div
      className={styles.container}
      data-collapsed={collapsed}
      ref={itemRef}
      onClick={() => toggleHandle()}
    >
      <p className={styles.title}>{collapsed ? "Models" : "Models status"}</p>
      <div className={styles.infoSection}>
        <div className={styles.iconWrapper}>
          {/* {itemsInfo?.isFinished ? <DoneIcon /> : null} */}
          {itemsInfo.hasRunningItems ? <Loader small /> : <DoneIcon />}
        </div>
        <div className={styles.itemsGroup}>
          {itemsInfo.strategies ? (
            <span className={styles.item}>
              <p className={styles.itemLabel}>Strategies</p>
              <Chips type="primary" label={itemsInfo.strategies} size="small" />
            </span>
          ) : null}
          {itemsInfo.portfolios ? (
            <span className={styles.item}>
              <p className={styles.itemLabel}>Portfolios</p>
              <Chips type="primary" label={itemsInfo.portfolios} size="small" />
            </span>
          ) : null}
        </div>
      </div>
      <SideList
        position={position}
        toggle={toggle}
        onAction={actionHandle}
        isParentCollapsed={collapsed}
        itemsInfo={itemsInfo}
      />
    </div>
  );
};

interface ISideListProps {
  position: { bottom: number; left: number } | null;
  toggle: boolean;
  isParentCollapsed: boolean;
  onAction: (item: ILivePerformanceItem) => void;
  itemsInfo: ITaskInfo | null;
}

const SideList: FC<ISideListProps> = memo(
  ({ position, toggle, isParentCollapsed, itemsInfo, onAction }) => {
    const getStrategyName = (strategy: ILivePerformanceItem): string => {
      const { meta, task_id, updated_at } = strategy;
      if (meta.name) return meta.name;
      if (task_id) return `Strategy-${task_id}`;
      return `Strategy-${dayjs(updated_at).format(DATE_ISO_TIME)}`;
    };

    if (position === null || toggle === false) return null;

    return REF_CONTAINER
      ? createPortal(
          <div
            className={styles.sideSection}
            style={position}
            data-collapsed={isParentCollapsed}
            data-exclude-id="models-list"
          >
            <ul className={styles.list}>
              {itemsInfo?.strategiesList ? (
                <Fragment>
                  <li className={styles.listItem} data-title>
                    Strategies
                  </li>
                  {itemsInfo.strategiesList.slice(0, 10).map((strategy, index) => (
                    <li className={styles.listItem} key={index} onClick={() => onAction(strategy)}>
                      <span className={styles.listItemLabel}>
                        {index + 1}. {getStrategyName(strategy)}
                      </span>
                      {strategy.status === "PENDING" || strategy.status === "STARTED" ? (
                        <span>
                          <Chips type="secondary" label="Progress" size="small" />
                        </span>
                      ) : null}
                      {strategy.status === "SUCCESS" ? (
                        <span>
                          <Chips type="success" label="Finished" size="small" />
                        </span>
                      ) : null}
                      {strategy.status === "FAILURE" ? (
                        <span>
                          <Chips type="error" label="Failed" size="small" />
                        </span>
                      ) : null}
                    </li>
                  ))}
                </Fragment>
              ) : null}
              {/* <li className={styles.listItem} data-title>
        Portfolios
      </li>
      <li className={styles.listItem} onClick={() => onAction("6")}>
        1. My test strategy 1
      </li>
      */}
            </ul>
          </div>,
          REF_CONTAINER
        )
      : null;
  }
);
