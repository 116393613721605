export const Errors = {
  data: {
    get: "Sorry, something went wrong during getting data",
  },
  create: {
    startegy: "Sorry, something went wrong during Creating new strategy.",
  },
  update: {
    strategy: "Sorry, something went wrong during Updating strategy.",
    permissions: "Sorry. something went wrong during Updating user permissions.",
  },
  delete: {
    strategy: "Sorry, something went wrong during Delete strategy.",
  },
  copy: {
    strategy: "Sorry, something went wrong during copy strategy.",
    template: "Sorry, something went wrong during copy template strategy.",
  },
  preview: {
    strategy:
      "Something went wrong while building the performance preview. Please try again later.",
    retriesLimit:
      "The strategy performance engine is experiencing high load. Please try to restart the performance shortly.",
  },
};
