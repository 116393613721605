import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ExtendedColumns } from "@/components";
import { modelMessage } from "@/constants";
import { useAppSelector } from "@/hooks";
import { useGetPerformanceListQuery } from "@/services/apiQuery/livePerformance";
import { strategiesState } from "@/store/strategies/strategies";
import {
  createStrategyTableColumns,
  createPerformanceSeriesData,
  prepareStrategyTable,
} from "@/utils";

export const usePreviewModelingResult = (isTemplate = false) => {
  const [isPreviewReady, setIsPreviewReady] = useState(false);
  const { strategy } = useAppSelector(strategiesState);
  const [searchParams] = useSearchParams();

  const { data: tasksList } = useGetPerformanceListQuery(null);
  const taskID = searchParams.get("task_id");
  const hasPerformance = searchParams.get("has_performance");
  const hasScheduled = searchParams.get("scheduled");

  const isModelingPerformance = useMemo(() => {
    const foundTask =
      tasksList?.find(
        (task) => task.task_id === taskID && Boolean(task.meta.is_template) === isTemplate
      ) || null;
    return Boolean(foundTask && (foundTask.status === "PENDING" || foundTask.status === "STARTED"));
  }, [tasksList, taskID, isTemplate]);

  const dateRange = useMemo(() => {
    const foundTask =
      tasksList?.find(
        (task) => task.task_id === taskID && Boolean(task.meta.is_template) === isTemplate
      ) || null;
    return {
      end: foundTask?.meta.config.end_date || null,
      start: foundTask?.meta.config.start_date || null,
    };
  }, [taskID, tasksList]);

  const hasTaskResultFound = useMemo(() => {
    const foundTask =
      tasksList?.find(
        (task) => task.task_id === taskID && Boolean(task.meta.is_template) === isTemplate
      ) || null;
    return Boolean(foundTask && (foundTask.status === "SUCCESS" || foundTask.status === "FAILURE"));
  }, [tasksList, taskID, isTemplate]);

  const strategyPerformance = (strategy && strategy[0]) || null;
  const isBenchmarkData = !!strategyPerformance?.data.equity.benchmarkLine;

  const tableData = useMemo(() => {
    return strategyPerformance ? prepareStrategyTable(strategyPerformance.data) : [];
  }, [strategy, strategyPerformance]);

  const chartData = useMemo(() => {
    return createPerformanceSeriesData(strategyPerformance);
  }, [strategy, strategyPerformance]);

  const columns: ExtendedColumns = useMemo(
    () => createStrategyTableColumns(isBenchmarkData),
    [isBenchmarkData]
  );

  const noData = useMemo(() => {
    if (strategy === null) return false;
    return (
      (!chartData.some((i) => i.data.length > 0) && !tableData.length) || strategy?.length === 0
    );
  }, [chartData, tableData, strategy]);

  const getEmptyResultTitle = useCallback(() => {
    if (isModelingPerformance) return modelMessage.title.progress;
    if (hasScheduled && !taskID) return modelMessage.title.viewResult;
    if (noData && hasPerformance === "true") return modelMessage.title.emptyResult;
    if (!noData && hasPerformance === "true") return null;
    if (hasTaskResultFound && hasScheduled) return modelMessage.title.ready;
    return null;
  }, [isModelingPerformance, hasPerformance, hasScheduled, noData, taskID, hasTaskResultFound]);

  const getEmptyResultInfo = useCallback(() => {
    if (isModelingPerformance) return modelMessage.info.progress;
    if (hasScheduled && !taskID) return modelMessage.info.viewResult;
    if (noData && hasPerformance === "true") return modelMessage.info.emptyResult;
    if (!noData && hasPerformance === "true") return null;
    if (hasTaskResultFound && hasScheduled) return modelMessage.info.ready;
    return null;
  }, [isModelingPerformance, hasPerformance, hasScheduled, noData, hasTaskResultFound]);

  useEffect(() => {
    if (strategy) setIsPreviewReady(true);
    else setIsPreviewReady(false);
  }, [strategy]);

  return {
    getEmptyResultTitle,
    getEmptyResultInfo,

    isPreviewReady,
    isModelingPerformance,
    tableData,
    chartData,
    dateRange,
    noData,
    columns,
  };
};
